var standardVariable = {};

$(document).ready(function() {

	var pageType = $('.C0009').length ? 'pdp' : 'nonPdp';
	var pagePurpose = _dl.page_name.page_purpose;
	
	//cart 정보 가져오기
	var	cartInfo = getGNBCart();
	
	//login user 일경우 wishdata 가져오기
	var wishData = getWishdata();
	
	//button count & product count
	var addToCartCount = 0;
	var whereToBuyCount = 0;
	var buyNowCount = 0;
	var inquireToBuyCount = 0;
	var findDealerCount = 0;
	var productCount = 0;

	var $productsInfo = $('.products-info');
	var $productsInfoBtns = $productsInfo.find('.button').children('.active');
	var $infoArea = $('.info-area');
	var $infoAreaBtns = $infoArea.find('.button').children();
	var $detailInfo = $('.detail-info');
	var $detailInfoBtns = $detailInfo.find('.btn-cover').children();
	var $bundle = $('.bundle');
	var $bundleBtns = $bundle.find('.btn-type-box').children();
	var $promotionBanner = $('#promotionHeader');
	var $nav = $('.navigation');

	var isB2C = $nav.hasClass('b2c');
	var pdpModelTitleClass = isB2C ? '.model-title-pdp-new' : '.model-title';
	
	productCount = $productsInfo.length + $infoArea.length + $detailInfo.length + $bundle.length;
	if(pagePurpose != 'discontinued'){
		addToCartCount = $productsInfoBtns.filter('.add-to-cart').length + $infoAreaBtns.filter('.add-to-cart').length + $detailInfoBtns.filter('.add-to-cart').length + $bundleBtns.filter('.add-to-cart').length;
		inquireToBuyCount = $productsInfoBtns.filter('.inquiry-to-buy').length + $detailInfoBtns.filter('.inquiry-to-buy').length + $bundleBtns.filter('.inquiry-to-buy').length;
		findDealerCount = $productsInfoBtns.filter('.find-a-dealer').length + $detailInfoBtns.filter('.find-a-dealer').length + $bundleBtns.filter('.find-a-dealer').length;
		
		$productsInfoBtns.filter('.where-to-buy').each(getCountWTBTxt);
		$infoAreaBtns.filter('.where-to-buy').each(getCountWTBTxt);
		$detailInfoBtns.filter('.where-to-buy').each(getCountWTBTxt);
		$bundleBtns.filter('.where-to-buy').each(getCountWTBTxt);
	}
	//button count & product count end
	
	//PDP count
	if(pageType == 'pdp'){
		var $pdpInfo = $('.pdp-info.desktop-info');
		var $productsInfoBtns = $pdpInfo.find('.button').children();
		
		productCount += $pdpInfo.length;
		addToCartCount += $productsInfoBtns.filter('.add-to-cart').length;
		inquireToBuyCount += $productsInfoBtns.filter('.inquiry-to-buy').length;
		findDealerCount += $productsInfoBtns.filter('.find-a-dealer').length;
		
		$productsInfoBtns.filter('.where-to-buy').each(getCountWTBTxt);
	}
	
	// StandVariable setting
	var compareCookie = getCookie('LG5_CompareCart');
	var productCompare = false;
	var noProductCompare = 0;
	var compareSku = '';
	if(compareCookie != undefined){
		productCompare = true;
		noProductCompare = compareCookie.split('|').length;
		var modelIds = fixedEncodeURI(compareCookie.split('|'));
		var url = '/us/mkt/api/compare/retrieveCompareProducts'
		ajax.call(url, {modelIds : modelIds} , 'json', function (data) {
			if(data.dataCount > 0){
				for(var i = 0; i < data.dataCount; i++){
					if(i + 1 < data.dataCount){
						compareSku += data.data[i] + "|";
					}else{
						compareSku += data.data[i];
					}
				}
				standardVariable.skuCompare = compareSku;
			}
		});
	}
	
	var dealArray = $('.pdp-summary-area').find('.promotion').first().children();
	var promotionList = '';
	for(var i=0; i < dealArray.length; i++){
		var liList = dealArray[i];
		var promotion = $(liList).find('a').text();
		promotionList += promotion + '|';	
	}
	
	standardVariable.event = 'dataLayerLoaded';
	//DC-312, DC-405
	standardVariable.businessUnit = checkBU(_dl.page_name.bu);
	standardVariable.country_code = _dl.country_code;
	standardVariable.siteType = standardData.siteType;
	standardVariable.buyNowCount = buyNowCount;
	standardVariable.addToCartCount = addToCartCount;
	standardVariable.whereToBuyCount = whereToBuyCount;
	standardVariable.inquireToBuyCount = inquireToBuyCount;
	standardVariable.findDealerCount = findDealerCount;
	standardVariable.pageType = pagePurpose;
	standardVariable.cartLength = cartInfo ? cartInfo.count : 0;
	standardVariable.cartValue = cartInfo ? parseFloat(cartInfo.amount.toFixed(2)) : 0; //float
	standardVariable.superCategory = _dl.page_name.super_category;
	standardVariable.primaryCategory = _dl.page_name.category;
	standardVariable.subCategory = _dl.page_name.sub_category;
	standardVariable.page_category_l1 = _dl.page_category_l1;
	standardVariable.page_category_l2 = _dl.page_category_l2;
	standardVariable.page_category_l3 = _dl.page_category_l3;
	standardVariable.page_category_l4 = _dl.page_category_l4;
	standardVariable.language = _dl.language_code;
	standardVariable.productCompare = productCompare;
	standardVariable.noProductCompare = noProductCompare;
	standardVariable.skuCompare = compareSku;
	standardVariable.siteVersion = '5.0';
	standardVariable.productCount = productCount;
	standardVariable.userId = '';
	standardVariable.loginState = false; // LGEUS-188
	standardVariable.micrositeName = '';
	standardVariable.pagePurpose = 'template';
	standardVariable.wishlistLength = wishData ? wishData.count : 0;
	standardVariable.wishlistSKU = wishData ? wishData.model : '';
	
	dataLayer.push(standardVariable);
	
	//PAGE LOAD setting
	var productArray = new Array();
	//page type = home , category, promotion etc..
	if(pageType == 'nonPdp'){
		
		var categoryName = '';
		if(_dl.page_name.page_purpose == 'home'){
        	categoryName = 'homepage'
        }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
        	categoryName = nvl(_dl.page_name.sub_category,_dl.page_name.category)
        	categoryName = nvl(categoryName,_dl.page_name.super_category)
        }
		
		//c0003,c0004,c0026,c0007,c0012,c0022
		if($productsInfo.length){
			$('.products-info').each(function(idx , item){
				var productsMap = productMapInit();
				productsMap.id = nvl($(item).data('ga-modelname'),'');
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				productsMap.list = nvl(getList(categoryName,templateName,componentName),'');
				productsMap.position = nvl(componentName + ' - ' + $(item).data('product-index'),'');
				
				if(pagePurpose != 'discontinued'){
					var $buttonList = $(item).find('.button').children('.active');
					$buttonList.each(function(idx,item){
						if($(item).hasClass('add-to-cart')){
							productsMap.dimension36 = 'Y';
						}
						if($(item).hasClass('where-to-buy')){
							var txt = $(this).text().toLowerCase();
							if(txt == 'where to buy'){
								productsMap.dimension37 = 'Y';
							}else if(txt == 'buy now'){
								productsMap.dimension51 = 'Y';
							}else if(txt == 'sign up'){
								productsMap.dimension116 = 'Y';
							}else if(txt == 'pre-order'){
								productsMap.dimension117 = 'Y';
							}else if(txt == 'notify me'){
								productsMap.dimension118 = 'Y';
							}
						}
						if($(item).hasClass('inquiry-to-buy')){
							productsMap.dimension38 = 'Y';
						}
						if($(item).hasClass('find-a-dealer')){
							productsMap.dimension95 = 'Y';
						}
					});
				}
				productArray.push(productsMap);
			});
		}
		//c0058
		if($('.info-area').length > 0){
			$('.info-area').each(function(idx , item){
				var productsMap = productMapInit();
				productsMap.id = nvl($(item).data('ga-modelname'),'');
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				productsMap.list = nvl(getList(categoryName,templateName,componentName),'');
				productsMap.position = nvl(componentName + ' - ' + $(item).data('product-index'),'');
				
				var button = $(item).find('.button').children();
				if(button.hasClass('add-to-cart')){
					productsMap.dimension36 = 'Y';
				}
				if(button.hasClass('where-to-buy')){
					var txt = $(this).text().toLowerCase();
					if(txt == 'where to buy'){
						productsMap.dimension37 = 'Y'
					}else if(txt == 'buy now'){
						productsMap.dimension51 = 'Y'
					}else if(txt == 'sign up'){
						productsMap.dimension116 = 'Y'
					}else if(txt == 'pre-order'){
						productsMap.dimension117 = 'Y'
					}else if(txt == 'notify me'){
						productsMap.dimension118 = 'Y'
					}
				}
				productArray.push(productsMap);
			});
		}
		//c0082
		if($('.detail-info').length > 0){
			$('.detail-info').each(function(idx , item){
				var productsMap = productMapInit();
				productsMap.id = $(item).find('.sku').text();
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				productsMap.list = nvl(getList(categoryName,templateName,componentName),'');
				productsMap.position = nvl(componentName + ' - ' + $(item).data('product-index'),'');
				
				var $buttonList = $(item).find('.btn-area').find('.btn-cover').children();
				$buttonList.each(function(idx,item){
					if($(item).hasClass('add-to-cart')){
						productsMap.dimension36 = 'Y'
					}
					if($(item).hasClass('where-to-buy')){
						var txt = $(this).text().toLowerCase();
						if(txt == 'where to buy'){
							productsMap.dimension37 = 'Y'
						}else if(txt == 'buy now'){
							productsMap.dimension51 = 'Y'
						}else if(txt == 'sign up'){
							productsMap.dimension116 = 'Y'
						}else if(txt == 'pre-order'){
							productsMap.dimension117 = 'Y'
						}else if(txt == 'notify me'){
							productsMap.dimension118 = 'Y'
						}
					}
					if($(item).hasClass('inquiry-to-buy')){
						productsMap.dimension38 = 'Y'
					}
					if($(item).hasClass('find-a-dealer')){
						productsMap.dimension95 = 'Y'
					}
				});
				productArray.push(productsMap);
			});
		}
		//c0006,c0021
		if($('.bundle').length > 0){
			$('.bundle').each(function(idx , item){
				var productsMap = productMapInit();
				productsMap.id = nvl($(item).find('.ga-model-info').data('ga-modelname'),'');
				var componentName = nvl($(item).closest('section').attr('class').split(' ')[1],'');
				productsMap.list = nvl(getList(categoryName,templateName,componentName),'');
				productsMap.position = nvl(componentName + ' - ' + $(item).find('a').data('product-index'),'');
				
				var $buttonList = $(item).find('.btn-type-box').children();
				$buttonList.each(function(idx,item){
					if($(item).hasClass('add-to-cart')){
						productsMap.dimension36 = 'Y'
					}
					if($(item).hasClass('where-to-buy')){
						var txt = $(this).text().toLowerCase();
						if(txt == 'where to buy'){
							productsMap.dimension37 = 'Y'
						}else if(txt == 'buy now'){
							productsMap.dimension51 = 'Y'
						}else if(txt == 'sign up'){
							productsMap.dimension116 = 'Y'
						}else if(txt == 'pre-order'){
							productsMap.dimension117 = 'Y'
						}else if(txt == 'notify me'){
							productsMap.dimension118 = 'Y'
						}
					}
					if($(item).hasClass('inquiry-to-buy')){
						productsMap.dimension38 = 'Y'
					}
					if($(item).hasClass('find-a-dealer')){
						productsMap.dimension95 = 'Y'
					}
				});
				productArray.push(productsMap);
			});
		}
	}

	// DC-1787 Holiday Pencil Banner
	if ($promotionBanner.length && getCookie('Holiday_Banner_Open') !== 'N') {
		var $holidayCTAButton = $promotionBanner.find('.btn.btn-primary');
		var $promotionCloseIcon = $promotionBanner.find('#holidayPromoClose');
		
		var holidayBannerEvent = 'pencilBanner';
		var holidayBannerClickEvent = 'lgEvent';
		var holidayBannerAction = 'pencil banner';
		var holidayBannerImpressionLabel = 'impression';
		var holidayBannerCTALabel = $holidayCTAButton.text();
		var holidayBannerCloseLabel = 'close';
		var holidayBannerBU = _dl.page_name.bu;

		// impression event
		addData(holidayBannerEvent, templateName, holidayBannerAction, holidayBannerImpressionLabel, holidayBannerBU, '');

		// Close promotion event
		$promotionCloseIcon.on('click', function() {
			addData(holidayBannerClickEvent, templateName, holidayBannerAction, holidayBannerCloseLabel, holidayBannerBU, '');
		});

		// holiday banner CTA button event (if CTA button is set via admin)
		if ($holidayCTAButton.length) {
			$holidayCTAButton.on('click', function() {
				addData(holidayBannerClickEvent, templateName, holidayBannerAction, holidayBannerCTALabel, holidayBannerBU, '');
			});
		}
		
	}
	
	//promotions setting
	var promotionArray = new Array();
	if($('.C0027').length || $('.C0028').length || $('.C0029').length || $('.C0030').length){
		$('.unit-box').each(function(idx , item){
			if($(item).closest('.component').attr('class') != 'component C0029'){
				var promotionId = nvl($(item).find('.btn-box span a').data('promotion-id'),'');
				if(promotionId != ''){
					var promotionDate = $(item).find('.body-copy').text();
					var startDateValue = $.trim(nvl(promotionDate,'').split('~')[0]);
					var endDateValue = $.trim(nvl(promotionDate,'').split('~')[1]);
					var startDateArray = startDateValue.split('/');
					var endDateArray = endDateValue.split('/');
					
					var startDate = ''; 
					var endDate = '';
					if(startDateArray.length < 3){
						startDate = endDateArray[2] + '-' + startDateArray[0] + '-' + startDateArray[1];
					}else if(startDateArray.length >= 3){
						startDate = startDateArray[2] + '-' + startDateArray[0] + '-' + startDateArray[1];
					}
					endDate = endDateArray[2] + '-' + endDateArray[0] + '-' + endDateArray[1];
					
					var title = $(item).find('.head-copy').text();
					var creative = nvl($(item).find('.image img').data('src'),'');
					var componentName = nvl($(item).closest('.component').attr('class'),'').split(' ')[1];
					var position = nvl($(item).find('.btn-box span a').data('promotion-index'),'');
					var promotionMap = addPromotionMap(promotionId, startDate, endDate, title, creative, componentName, position);
					promotionArray.push(promotionMap);
				}
			}else{
				var promotionId = nvl($(item).find('.btn-box span a').data('promotion-id'),'');
				if(promotionId != ''){
					var promotionDate = nvl($(item).find('.btn-box span a').data('promotion-date'),'');
					var startDate = $.trim(nvl(promotionDate,'').split('~')[0]);
					var endDate = $.trim(nvl(promotionDate,'').split('~')[1]); 
					var title = nvl($(item).find('.btn-box span a').data('promotion-name'),'');
					var creative = nvl($(item).find('.btn-box span a').data('promotion-file-path'),'');
					var componentName = nvl($(item).closest('.component').attr('class'),'').split(' ')[1];
					var position = nvl($(item).find('.btn-box span a').data('promotion-index'),'');
					var promotionMap = addPromotionMap(promotionId, startDate, endDate, title, creative, componentName, position);
					promotionArray.push(promotionMap);
				}
			}
		});
		
	}
	
	if($('.C0042').length > 0){
		$('.btn-area').each(function(idx , item){
			var promotionId = nvl($(item).find('span a').data('promotion-id'),'');
			if(promotionId != ''){
				var promotionDate = nvl($(item).find('span a').data('promotion-date'),'');
				var startDate = $.trim(nvl(promotionDate,'').split('~')[0]);
				var endDate = $.trim(nvl(promotionDate,'').split('~')[1]); 
				var title = nvl($(item).find('span a').data('promotion-name'),'');
				var creative = nvl($(item).find('span a').data('promotion-file-path'),'');
				var componentName = nvl($(item).closest('.component').attr('class'),'').split(' ')[1];
				var position = nvl($(item).find('span a').data('promotion-index'),'');
				var promotionMap = addPromotionMap(promotionId, startDate, endDate, title, creative, componentName, position);
				promotionArray.push(promotionMap);
			}
		});
	}
	
	if($('.C0043').length > 0 || $('.C0044').length > 0){
		$('.btn-type-box').each(function(idx , item){
			var promotionId = nvl($(item).find('span a').data('promotion-id'),'');
			if(promotionId != ''){
				var promotionDate = nvl($(item).find('span a').data('promotion-date'),'');
				var startDate = $.trim(nvl(promotionDate,'').split('~')[0]);
				var endDate = $.trim(nvl(promotionDate,'').split('~')[1]); 
				var title = nvl($(item).find('span a').data('promotion-name'),'');
				var creative = nvl($(item).find('span a').data('promotion-file-path'),'');
				var componentName = nvl($(item).closest('.component').attr('class'),'').split(' ')[1];
				var position = nvl($(item).find('span a').data('promotion-index'),'');
				var promotionMap = addPromotionMap(promotionId, startDate, endDate, title, creative, componentName, position);
				promotionArray.push(promotionMap);
			}
		});
	}
	
	if($('.C0055').length > 0 || $('.C0078').length > 0){
		$('.cta').each(function(idx , item){
			var ctaArray = $(item).find('span');
			$(ctaArray).each(function(idx , item){
				var promotion = '';
				if($(item).find('a').data('firstpromotion-id') != undefined || $(item).find('a').data('firstpromotion-id') != ''){
					promotion = 'firstpromotion';
				}else{
					promotion = 'secondpromotion'; 
				}
				var promotionId = nvl($(item).find('a').data(promotion+'-id'),'');
				if(promotionId != ''){
					var promotionDate = nvl($(item).find('a').data(promotion+'-date'),'');
					var startDate = $.trim(nvl(promotionDate,'').split('~')[0]);
					var endDate = $.trim(nvl(promotionDate,'').split('~')[1]);
					var title = nvl($(item).find('a').data(promotion+'-name'),'');
					var creative = nvl($(item).find('a').data(promotion+'-file-path'),'');
					var componentName = nvl($(item).closest('.component').attr('class'),'').split(' ')[1];
					var position = nvl($(item).find('a').data('promotion-index'),'');
					var promotionMap = addPromotionMap(promotionId, startDate, endDate, title, creative, componentName, position);
					promotionArray.push(promotionMap);
				}
			});
		});
	}
	
	if(productArray.length > 0 || promotionArray.length > 0){
		dataLayer.push({"event":"eec.impressions","impressions":productArray,"promotions":promotionArray});
	}
	
	//PDP setting
	if(pageType == 'pdp'){
		var productDetailArray = new Array();
		var ecommerce = {};
		var detail = {};
		var products = {};
		var productsArray = new Array();
		var handyFlag = 'N';
		if ($('.wall-mounting-area').length) {
			handyFlag = 'Y';
		}
		
		ecommerce.currencyCode = 'USD';
		var nameSelector = isB2C ? pdpModelTitleClass : '.pdp-info.ga-model-info ' + pdpModelTitleClass;
		
		products.id = nvl(_dl.page_name.sku,'');
		products.name = $(nameSelector).text();
		products.category = nvl(_dl.page_name.category,'');
		var price = $.trim($('.pdp-info.ga-model-info').find('.price-area').find('.purchase-price').text());
		products.price = Number(price.replace(/[^0-9.-]+/g,""));
		products.dimension7 = nvl(_dl.products[0].sales_model_code,'');
		products.dimension8 = nvl(_dl.products[0].model_code,'');
		products.dimension9 = checkBU(nvl(_dl.page_name.bu,'')); //DC-312, DC-405
		products.dimension10 = 0;
		products.dimension11 = 0;
		
		setTimeout(function(){
			var reviewCount = nvl($('#ratings-summary').find('.bv_numReviews_text').text().replace("(","").replace(")",""),''); //LGEUS-523
			var reviewRating = nvl($('#ratings-summary').find('.bv_avgRating_component_container').text(),'');
			products.dimension10 = (reviewCount != '')? parseInt(reviewCount) : 0;
			products.dimension11 = (reviewRating != '')? parseFloat(reviewRating) : 0;
		},5000);
		
		products.dimension28 = 'N';
		products.dimension29 = 0;
		products.dimension36 = 'N';
		products.dimension37 = 'N';
		products.dimension38 = 'N';
		products.dimension39 = ''
		products.dimension51 = 'N'; 
		products.dimension95 = 'N';
		products.dimension96 = '';
		products.dimension116 = 'N';
		products.dimension117 = 'N';
		products.dimension118 = 'N';
		products.dimension142 = 'N';
		products.dimension157 = handyFlag;
		
		if($('.pdp-summary-area').find('.product-price del').length){
			products.dimension28 = 'Y'
			var discountAmount = nvl($('.pdp-summary-area').find('.ga-saveprice').text(),'').split(' ').reverse()[0];
			if(discountAmount != ''){
				products.dimension29 = Number(discountAmount.replace(/[^0-9.-]+/g,""));
			}else{
				products.dimension29 = 0;
			}
		}
		
		if($('.pdp-summary-area').find('.price-inner .in-stock').length){
			products.dimension142 = 'Y';
		}
		
		var summaryArea = $('.pdp-info.ga-model-info').find('.button');
		if(summaryArea.find('.add-to-cart').length){
			products.dimension36 = 'Y'
		}
		if(summaryArea.find('.where-to-buy').length){
			var txt = summaryArea.find('.where-to-buy').text().toLowerCase();
			if(txt == 'where to buy'){
				products.dimension37 = 'Y'
			}
			if(txt == 'buy now'){
				products.dimension51 = 'Y'
			}
			if(txt == 'sign up'){
				products.dimension116 = 'Y'
			}
			if(txt == 'pre-order'){
				products.dimension117 = 'Y'
			}
			if(txt == 'notify me'){
				products.dimension118 = 'Y'
			}
		}
		if(summaryArea.find('.inquiry-to-buy').length){
			products.dimension38 = 'Y'
		}
		if(summaryArea.find('.find-a-dealer').length){
			products.dimension95 = 'Y'
		}
		
		var dealArray = $('.pdp-info.ga-model-info').find('.promotion').children();
		var promotionList = '';
		for(var i=0; i < dealArray.length; i++){
			var liList = dealArray[i];
			var promotion = $(liList).find('a').text();
			promotionList += promotion + '|';	
		}
		
		products.dimension39 = promotionList.substr(0, promotionList.length -1);
		products.dimension96 = _dl.page_name.sub_category_list;
		
		productsArray.push(products);
		detail.products = productsArray;
		ecommerce.detail = detail;
		
		//products data
		if($('.products-info').length > 0 ){
			$('.products-info').each(function(idx , item){
				var id = $(item).find('.sku').text();
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				var position = nvl($(item).data('product-index'),'');
				
				var valueProductDetailMap = productDetailMap(id,componentName,position);
				productDetailArray.push(valueProductDetailMap);
			});
		}
		
		if($('.info-area').length > 0){
			$('.info-area').each(function(idx , item){
				var id = nvl($(item).find('.model-name a').data('link-name'),'');
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				var position = nvl($(item).data('product-index'),'');
				
				var valueProductDetailMap = productDetailMap(id,componentName,position);
				productDetailArray.push(valueProductDetailMap);
			});
		}
		
		if($('.detail-info').length){
			$('.detail-info').each(function(idx , item){
				var id = $(item).find('.sku').text();
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				var position = nvl($(item).data('product-index'),'');
				
				var valueProductDetailMap = productDetailMap(id,componentName,position);
				productDetailArray.push(valueProductDetailMap);
			});
		}
		
		if($('.bundle').length){
			$('.bundle').each(function(idx , item){
				var id = $(item).find('.sku').text();
				var componentName = nvl($(item).closest('section').attr('class'),'').split(' ')[1];
				var position = nvl($(item).find('a').data('product-index'),'');
				
				var valueProductDetailMap = productDetailMap(id,componentName,position);
				productDetailArray.push(valueProductDetailMap);
			});
		}
		
		if(productDetailArray.length > 0){
			dataLayer.push({"event":"eec.detail", "ecommerce":ecommerce, "impressions":productDetailArray});
		}else{
			dataLayer.push({"event":"eec.detail", "ecommerce":ecommerce});
		}
		
	}
	
	function getCountWTBTxt() {
		var txt = $(this).text();
		txt === 'Where to Buy' && whereToBuyCount++;
		txt === 'Buy Now' && buyNowCount++;
	}
	
	function getList(categoryName, templateName, componentName) {
		var returnValue = '';
		if(categoryName != ''){
			returnValue = nvl(categoryName + ' - ' + templateName + ' - ' + componentName,'');
		}else{
			returnValue = nvl(templateName + ' - ' + componentName,'');
		}
		return returnValue;
	}

	//DC-722 GA360 tagging, javascript Start
	$('.product-rebate .ecorebates-div').click(function(){
		var label = nvl_def($(this).closest('.ga-model').find('.ga-model-info').attr('data-ga-modelname'));
		var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase();
		if(!bu) bu = nvl_def(_dl.page_name.bu);
		var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1];
		var targetCategory = templateName;
		var categoryName = "";
    	var event = 'lgEvent';
    	var action = "eco-rebate";
		// nonPdp
		if (pageType == 'nonPdp') {
			categoryName = nvl(_dl.page_name.sub_category,_dl.page_name.category);
	    	categoryName = nvl(categoryName,_dl.page_name.super_category);
	    	if (categoryName != "") {
	    		targetCategory = categoryName + " - " + templateName;
	    	} else {
	    		targetCategory = templateName;
	    	}
		}
			
		addData(event, targetCategory, action, label, bu , componentId);
	});
	//DC-722 GA360 tagging, javascript End
});

// Get element text without web accessibility text for tagging label
function getElementText($el) {
	return $el.contents().filter(function(i, el) {
		return el.className !== 'visually-hidden'
	}).text();
}

window.onload = function() {
    console.log("Load finish");
    
    $(document).on("mouseup",'div.slider-wrap',function(event){

    	if($(this).parents().hasClass('filter-box')){
     		
    		var event = 'lgEvent'
			var category = ''
 			   
			if(_dl.page_name.page_purpose == 'home'){
            	category = makeString('homepage',templateName)
            }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
            	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
            	category = nvl(category,_dl.page_name.super_category)
            	category = makeString(category,templateName)
            }else{
            	category = templateName
            }
	   
           var action = 'filter interacton'
           var cta = $(this).find('.slider-range').find('span').eq(0).attr('aria-valuetext') + "|" + $(this).find('.slider-range').find('span').eq(1).attr('aria-valuetext')
           var filterHeading = nvl_def($(this).closest('.option-box').find('.title strong').text())
 		   var label = makeString(filterHeading,cta)
 		   var bu = nvl_def(_dl.page_name.bu)
 		   var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
 		   
 		  addData(event,category,action,label, bu , componentId )
 	    
     	}
    });

    $(document).on("mouseup",'label.checkbox-box',function(event){

     	if($(this).parents().hasClass('filter-box')){

           var event = 'lgEvent'
          
    	   var category = ''
		   if(_dl.page_name.page_purpose == 'home'){
           	category = makeString('homepage',templateName)
           }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
           	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
           	category = nvl(category,_dl.page_name.super_category)
           	category = makeString(category,templateName)
           }else{
           	category = templateName
           }
    		   
    		   
           var action = 'filter interacton'
        	   
           var cta = nvl_def($(this).text())
           cta = cta.substring(0,cta.indexOf('('))
           var filterHeading = nvl_def($(this).closest('.option-box').find('.title strong').text())
           
           var label =  makeString(filterHeading,cta)
      
           var bu = nvl_def(_dl.page_name.bu)
           var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
           
           addData(event,category,action,label, bu , componentId )
     	}
     	//PJUSPDP-1
     	if($(this).children().first().hasClass('js-compare')){
			var action = ''
			var label = nvl_def(_dl.page_name.sku)
			var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase();
			if(!bu) bu = nvl_def(_dl.page_name.bu);
			var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
			var category = templateName;
			event = 'lgEvent';
			if($(this).children().first().hasClass('added')){
				action = 'remove compare';
			}else{
				action = 'add to compare';
			}
			 addData(event,category,action,label, bu , componentId );
     	}
     	//PJUSPDP-1
    });


	$(document).on("mousedown",'a, div.ico-compare',function(event){
        switch(true){
            //Footer Start
            //Footer social icon
            case $(this).parents().hasClass('social') :
            	var label = ''
                if($(this).hasClass('social-icon')){
                    label = nvl_def($(this).attr('data-link-name'))
                }else if($(this).hasClass('social-support-fb')){
                	label = 'Facebook'
                }else if($(this).hasClass('social-support-tw')){
                	label = 'Twitter'
                }  
            	var bu = nvl_def(_dl.page_name.bu)
                addData('footerNavigation','footer navigation','social link click',label,bu,'C0002')
            break;
            //.closest('.category-block').find('span a.category-button').text()
            	
            //Footer nav
            case $(this).parents().hasClass('footer-contents') :
            	var label = ""
            		
            	if($(this).parents().hasClass('visible-mobile')){
            		if($(this).attr('data-link-area') == "footer-super_category"){
                        label = nvl_def($(this).text())
                    }else if($(this).attr('data-link-area') == "footer-category"){
                    	var p1 = nvl_def($(this).closest('.category-block').find('span a.category-button').text())
                    	label = makeString(p1 ,$(this).text() )
                    	
                    }else if($(this).attr('data-link-area') == "footer-sub_category"){
                    	
                    	var p1 = nvl_def($(this).closest('.category-block').find('span a.category-button').text())
                    	
                    	if($(this).closest('li').prevAll('.depth-title').length > 0){
                    		
                    		var p2 = nvl_def($(this).closest('li').prevAll('.depth-title').first().children('a').text())
                    		label = p1+ " - " +p2+ " - " +$(this).text()
                    		
                    	}else{
                    		label = makeString(p1 , $(this).text() )
                    	}

                    }else if($(this).attr('data-link-area') == "footer-social_my_account"){
         
                        if($(this).parent().hasClass('footer-title')){
                            label = nvl_def($(this).text())
                        }else{
                            var p1 = $(this).closest('.link-wrap').find('.footer-title')
                            label = makeString(p1.text() , $(this).text() )
                        }
                        
                    }else if($(this).attr('data-link-area') == "footer-community_forum"){
                    	label = nvl_def($(this).text())
                    }
            	}else{
            		if($(this).attr('data-link-area') == "footer-super_category"){
                        label = nvl_def($(this).text())
                    }else if($(this).attr('data-link-area') == "footer-category"){
                        
                    	var p1 = $(this).closest('.link-wrap').children('.footer-title')

                    	label = makeString(p1.text() , $(this).text() )
                    	
                    }else if($(this).attr('data-link-area') == "footer-sub_category"){
                    	
                    	var p1 = $(this).closest('.link-wrap').children('.footer-title')
                    	if($(this).closest('.footer-sub-title').first().children('a').length > 0){
                    		
                    		var p2 = $(this).closest('.footer-sub-title').first().children('a')
                    		label = p1.text()+ " - " +p2.text()+ " - " +$(this).text()
                    		
                    	}else{

                    		label = makeString(p1.text() , $(this).text() )
                    	}

                    }else if($(this).attr('data-link-area') == "footer-social_my_account"){
         
                        if($(this).parent().hasClass('footer-title')){
                            label = nvl_def($(this).text())
                        }else{
                            var p1 = $(this).closest('.link-wrap').find('.footer-title')
                            label = makeString(p1.text() , $(this).text() )
                        }
                        
                    }else if($(this).attr('data-link-area') == "footer-community_forum" || $(this).attr('data-link-area').includes('footer-lg_support_chatbot')){
                    	label = nvl_def($(this).text())
                    }
            	}
                
                var bu = nvl_def(_dl.page_name.bu)
                
                addData('footerNavigation','footer navigation','footer item click',label,bu,'C0002')		
            break;
            
            //Footer End
            
            //GNB Start
            case $(this).parents().hasClass('navigation') :
            	
            	//search
            	if($(this).parents().hasClass('search-layer')){
            		var event = ''
                    var category = 'search layer'
                    var action = ''
                    var label =  nvl_def($(this).closest('li').find('.txt a.product').attr('data-adobe-modelname'))
                    var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase() //LGEUS-188
                    var componentId = 'n/a'
                    var eCommerceFlag = 'Y'
                    
                    if($(this).hasClass('product')  || $(this).hasClass('add-to-cart') || $(this).hasClass('where-to-buy') || $(this).hasClass('find-a-dealer')  ){
                        //product click, where-to-buy, add to cart, find a dealer
                    	
                        var ecommerce = new Object()
                        ecommerce.currencyCode = 'USD'
                        var addObj = new Object()
                        var productsObj = new Object()
                        var productDetailArray = new Array();
                        
                        addObj.products = productDetailArray
                        productDetailArray.push(productsObj)
                        productsObj.id = $(this).closest('li').find('.txt a.product').attr('data-adobe-modelname')
                        productsObj.name = nvl_def($(this).closest('li').find('.txt a.product').find('.name').text())
                        
                        var pcategory =  nvl_def($(this).closest('li').find('.txt a.product').attr('href'))
                        pcategory = pcategory.substring(pcategory.indexOf('/us'))
                        pcategory = pcategory.split('/')[2]
                        pcategory = nvl(pcategory,_dl.page_name.category)
                        productsObj.category = pcategory
                        productsObj.price =  '0'

                        var pIndex = $(this).closest('li').index()+1
                        productsObj.position = pIndex
                        	
                        if($(this).hasClass('add-to-cart')){
                            productsObj.quantity = '1'
                        }
                        
                        productsObj.dimension7 = $(this).closest('li').find('.txt a.product').attr('data-adobe-modelname')
                        productsObj.dimension8 = nvl_def($(this).closest('li').find('.add-to-cart').attr('data-model-id'))
                        //DC-312, DC-405
                        productsObj.dimension9 = checkBU(nvl_def(_dl.page_name.bu)) 
                        productsObj.dimension10 = '0'
                    	productsObj.dimension11 = '0'
                        productsObj.dimension28 = 'N'
                        productsObj.dimension29 = '0'
                        productsObj.dimension36 = 'N'
                        productsObj.dimension37 = 'N'
                        productsObj.dimension38 = 'N'
                        productsObj.dimension39 = ''
                        productsObj.dimension51 = 'N'
                        productsObj.dimension95 = 'N'
                        productsObj.dimension116 = 'N'
                        productsObj.dimension117 = 'N'
                        productsObj.dimension118 = 'N'

                    	if($(this).closest('li').find('.add-to-cart').length > 0){
                            productsObj.dimension36 = 'Y'
                        }

                        if($(this).closest('li').find('.inquiry-to-buy').length > 0){
                            productsObj.dimension38 = 'Y'
                        }
                        if($(this).closest('li').find('.find-a-dealer').length > 0){
                            productsObj.dimension95 = 'Y'
                        }

                        if($(this).closest('li').find('.where-to-buy').length > 0){
                        	var wtbText = nvl_def($(this).closest('li').find('.where-to-buy').text().toLowerCase())
                            if(wtbText == 'where to buy'){
                                productsObj.dimension37 = 'Y'
                            }else if(wtbText == 'buy now'){
                                productsObj.dimension51 = 'Y'
                            }else if(wtbText == 'sign up'){
                                productsObj.dimension116 = 'Y'
                            }else if(wtbText == 'pre-order'){
                                productsObj.dimension117 = 'Y'
                            }else if(wtbText == 'notify me'){
                                productsObj.dimension118 = 'Y'
                            }
                        }

						var subscriptionFlag = $(this).data('subscription-enabled'); //DC-905
                       
                        if($(this).hasClass('product')){
                            event = 'eec.productClick'
                            action = 'product click'
                            ecommerce.click = addObj
                        }else if($(this).hasClass('where-to-buy')){
                        	//LGEUS-499
                        	var txt = $(this).text().toLowerCase();
                        	if(txt == 'where to buy'){
	                            event = 'whereToBuyEvent'
	                            ecommerce.click = addObj
                        	}else{
                        		eCommerceFlag = 'N'
                        		event = 'lgEvent'
                        	}
                            action = 'search - ' + txt;
                        }else if($(this).hasClass('add-to-cart')){
							//DC-905 Modified
							if( subscriptionFlag && subscriptionFlag == 'N'){
								event = 'eec.addToCart'
								action = 'Add To Cart'
								productsObj.quantity = '1'
								ecommerce.add = addObj
							}else {
								event = 'lgEvent'
								action = 'subscription - pop up'
							}
							//DC-905 End
                        }else if($(this).hasClass('find-a-dealer')){
                            event = 'findDealerEvent'
                            action = 'Find a dealer'
                            ecommerce.click = addObj
                        }
                        //LGEUS-499
                        if(eCommerceFlag == 'Y' && (!subscriptionFlag || subscriptionFlag == 'N')){ //DC-905 Modified
	                        addData2(event, category, action, label, bu, componentId, ecommerce)
                        }else{
				            addData(event,category,action,label, bu , componentId )           
                        }
                    }else{
                    	
			        	if($(this).hasClass('inquiry-to-buy')){
			        		event = 'lgEvent'
			                action = 'search - inquire to buy'
			            }else if($(this).parents().hasClass('enhanced-category')){
			                event = 'lgEvent'
			                var txt = nvl_def($(this).text());
			                txt = txt.substring(0,txt.indexOf('('))
			                label = nvl_def(txt) 	
			                action = 'search - results by category'
			            }
			                    
                        addData(event,category,action,label, bu , componentId )
                    }
               
            	}else if($(this).parent().hasClass('banner-visual') || $(this).parents().hasClass('gnb-feature')){
            		 //2nd Level GNB
		            var bu = nvl_def(_dl.page_name.bu)
		            var label = nvl_def($(this).closest('.sublayer').prev().text())
		            	
		            if($(this).parent().hasClass('banner-visual')){
		            	label = label + " - PROMOTION BANNER"
		            	label = makeString(label, $(this).find('.banner-title').text() )
		            }else{
		            	label = label + " - FEATURE PRODUCT"
		            	label = makeString(label, $(this).find('.text .title').text() )
		            }
		            
                    addData('mainNavigation','main navigation','menu title click',label,bu,'C0001')	
                }else if ($(this).data('link-area') === 'gnb-lg_brand_logo') {
					var label = nvl_def($(this).attr('data-link-name')) + "";
					if(label == "" || label == null || label == "null" || label == "undefined"){
						label = nvl_def($(this).text());
					}
				
					var bu = nvl_def(_dl.page_name.bu);
					addData('headerNavigation','header navigation','header link click',label,bu,'C0001')	;
				}else if($(this).parents().hasClass('left-btm') || $(this).parents().hasClass('depth1-m')){
            		var label = ""
            	    var bu = nvl_def(_dl.page_name.bu)
            		if($(this).attr('data-link-area') == "gnb-b2c_primary_navigation_items" || $(this).attr('data-link-area') == "gnb-b2b_primary_navigation_items"){
            			label = nvl_def($(this).text())
            		}else if($(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-supercategory_link" || $(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-category_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-supercategory_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-category_link" ){
                        
            			var p1 = $(this).closest('.sublayer').prev().text()
                        p1 = nvl(p1,$(this).closest('.tablet-layer').prev().find('a[data-link-area="gnb-b2c_primary_navigation_items"].active').last().text())
                        
                        label = makeString( p1, getElementText($(this)));
                        
                    }else if($(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-subcategory_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-subcategory_link"){
                    	var p1 = $(this).closest('.sublayer').prev().text()
                        p1 = nvl(p1,$(this).closest('.tablet-layer').prev().find('a[data-link-area="gnb-b2c_primary_navigation_items"].active').last().text())
                        
                        if($(this).closest('li.sub-link').children('a').length > 0){
	                       	 var p2 = $(this).closest('li.sub-link').children('a')
	                       	 label = p1.trim()+ " - " +p2.text().trim()+ " - " + getElementText($(this)).trim()
                        }else{

                       	 	label = makeString( p1, getElementText($(this)));
                        }
                    }
            		addData('mainNavigation','main navigation','menu item click',label,bu,'C0001')
            	}
            //DC-117, DC-408 START
                else if($(this).parents().hasClass('depth2-m')){
            		var label = ""
            	    var bu = nvl_def(_dl.page_name.bu)
            		if($(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-supercategory_link" || $(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-category_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-supercategory_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-category_link" ){
                        
            			var p1 = $('#gnbB2C').find('li.super.active > a').text();
                        
						label = p1.trim() + " - " + getElementText($(this)).trim();
                        
                    }else if($(this).attr('data-link-area') == "gnb-b2c_exposed_mega_nav-subcategory_link" || $(this).attr('data-link-area') == "gnb-b2b_exposed_mega_nav-subcategory_link"){
						var p1 = $('#gnbB2C').find('li.super.active > a').text();
                        
                        if($(this).parents('.type2').length > 0 ){
	                       	 label = p1.trim() + " - " + getElementText($(this)).trim();
                        }else{
							label = getElementText($(this));
                        }
                    }
            		addData('mainNavigation','main navigation','menu item click',label,bu,'C0001')
            	} //DC-117, DC-408 END
                else if($(this).parents().hasClass('navi-top') || $(this).parents().hasClass('right-btm') || $(this).parents().hasClass('top-link') || $(this).parents().is('.mylg.active') || $(this).parents().hasClass('for-mobile')){
						//1st Level GNB
						var label = nvl_def($(this).attr('data-link-name')) + ""
						if(label == "" || label == null || label == "null" || label == "undefined"){
							label = nvl_def($(this).text())
						}
					
		            var bu = nvl_def(_dl.page_name.bu)
                    addData('headerNavigation','header navigation','header link click',label,bu,'C0001')	
                }
			
            
            break;    
            //GNB End
            
            //Sitemap Start
            case ($(this).parents().hasClass('category-cluster') && $(this).parents().hasClass('category-depth')):
            	var label = ""
                if($(this).parents().hasClass('level-1')){
                    label = nvl_def($(this).text())
                    
                }else if($(this).parents().hasClass('level-2')){
                    var p1 = $(this).closest('li.category-column').prevAll('.level-1').first()
                    label = makeString( p1.text(),$(this).text())
                    
                }else if($(this).parents().hasClass('level-3')){
                    var p1 = $(this).closest('li.category-column').prevAll('.level-1').first()
                    var p2 = $(this).parent().prevAll('.level-2').first()

                    label = makeString( p1.text(), p2.text())
                    label = makeString( label, $(this).text())
                }
            
                var bu = nvl_def(_dl.page_name.bu)
                addData('lgEvent','site map','site map navigation',label,bu,'C0041')	
            break;  
            //Sitemap End

            //Add to cart Start (PDP)
            case ($(this).parents().hasClass('pdp-tab') || $(this).parents().hasClass('pdp-info') || $(this).parents().hasClass('directFrom')):
                var category = nvl(_dl.page_name.sub_category,_dl.page_name.super_category)
				var isB2C = $('.navigation').hasClass('b2c');
                category = templateName
                var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase() //LGEUS-188
                var label = nvl_def(_dl.page_name.sku)
                var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
            	var productDetailArray = new Array();
            
				if($(this).hasClass('add-to-cart') || $(this).hasClass('subscription-add-to-cart')){ //DC-905 Modified
                    var event = 'eec.addToCart'
                    var action = 'Add To Cart'
                    var ecommerce = new Object()
                    bu = checkBU(nvl_def(_dl.page_name.bu)); // LGEUS-188, DC-312, DC-405
                    ecommerce.currencyCode = 'USD'
                    var productsObj = new Object()
                    productsObj.id = nvl_def(_dl.page_name.sku)
					var modelName = isB2C ? nvl_def($('.model-title-pdp-new').text()) : nvl_def($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.model-title').text());
                    productsObj.name = modelName;
					productsObj.category = nvl(_dl.page_name.category,_dl.page_name.super_category)
                    
                    var price = nvl_def($(this).closest('.iw_viewport-wrapper').find('.pdp-info.ga-model-info').find('.ga-price').text())
                    price = price.substr(price.indexOf('$')+1,price.length)
                    price = nvl(price,'0')
                    productsObj.price = replaceAll(price,',','')
                    
                    productsObj.position =  nvl_def($(this).closest('.component').attr('class')).split(' ')[1] + ' - 1' 
                    
                    if($(this).hasClass('add-to-cart')){
                        productsObj.quantity = '1'
                    }
                    
                    productsObj.dimension7 = nvl_def(_dl.page_name.sku) //LGEUS-188
                    productsObj.dimension8 = nvl_def($(this).closest('.iw_viewport-wrapper').find('div.component').attr('data-model-id'))
					productsObj.dimension9 = bu //LGEUS-188
                    productsObj.dimension10 = nvl($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.bv_numReviews_component_container').first().text().replace("(","").replace(")","").trim(),'0') // LGEUS-523
                    productsObj.dimension11 = nvl($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.bv_avgRating_component_container').first().text(),'0')
                    productsObj.dimension28 = 'N'
                    productsObj.dimension29 = '0'
                    productsObj.dimension36 = 'Y' //add to cart flag 
                    productsObj.dimension37 = 'N'
                    productsObj.dimension38 = 'N'
                	productsObj.dimension39 = ''
                    productsObj.dimension51 = 'N'
                    productsObj.dimension95 = 'N'
                    productsObj.dimension116 = 'N'
                    productsObj.dimension117 = 'N'
					productsObj.dimension118 = 'N'
					productsObj.dimension157 = 'N'
                    
                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.product-price del').length > 0){
                        productsObj.dimension28 = 'Y'
                        var savePrice = nvl_def($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.product-price div').first().text())
                        savePrice = savePrice.substr(savePrice.indexOf('$')+1,savePrice.length)
                        productsObj.dimension29 = replaceAll(savePrice,',','')
                    }

      
                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.where-to-buy').length > 0){
                        //Where to buy Where to Buy 37, Buy Now 51, Sign Up 116, Pre-Order 117, Notify Me 118
                    	var wtbText = nvl_def($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.where-to-buy').text().toLowerCase())
                        if(wtbText == 'where to buy'){
                            productsObj.dimension37 = 'Y'
                        }else if(wtbText == 'buy now'){
                            productsObj.dimension51 = 'Y'
                        }else if(wtbText == 'sign up'){
                            productsObj.dimension116 = 'Y'
                        }else if(wtbText == 'pre-order'){
                            productsObj.dimension117 = 'Y'
                        }else if(wtbText == 'notify me'){
                            productsObj.dimension118 = 'Y'
                        }
                    }

                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.add-to-cart').length > 0){
                    	productsObj.dimension36 = 'Y'
                    }
                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.inquiry-to-buy').length > 0){
                        productsObj.dimension38 = 'Y'
                    }
                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.promotion').find('a').length > 0){
                    	var promotions =$(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.promotion').find('a')
                    	var promotionList = ""
                		for ( var i = 0, l = promotions.length; i < l; i++ ) {
                			if(i==0){
                				promotionList = nvl_def(promotions.eq(i).text())
                			}else{
                				promotionList = promotionList+'|' + nvl_def(promotions.eq(i).text())
                			}
                		}
                    	productsObj.dimension39 = promotionList
                    }
                    if($(this).closest('.iw_viewport-wrapper').find('.pdp-summary-area').find('.ga-model-info').find('.find-a-dealer').length > 0){
                        productsObj.dimension95 = 'Y'
					}

					//DC-905 Start
					ecommerce.add = new Object();

					var subscriptionFlag = $(this).data('subscription-enabled');

					if(subscriptionFlag && subscriptionFlag == 'Y'){
						event = 'eec.addToCart';
						action = 'subscription options - add to cart';
						var listString = makeString(category, componentId) + ' - subscription options';
						ecommerce.add.actionField = {list : listString};
						productsObj.list = 'subscription options';
					}

					productDetailArray.push(productsObj);

					var handyData = addToCart.handyWallmountData;

					if (handyData) {
						productsObj.dimension157 = 'Y';

						if (!handyData.isServiceOnly) {
							var handyProductObj = {
								sku: handyData.sku,
								name: handyData.name,
								quantity: 1,
								price: handyData.price.toString(),
								category: ""
							};
							productDetailArray.push(handyProductObj);
						}
					}

					ecommerce.add.products = productDetailArray;
					//DC-905 End
                    
                    addData2(event, category, action, label, bu, componentId, ecommerce)
                        
                }else{
                    /*  Where to Buy                whereToBuyEvent
                        Inquiry to Buy              lgEvent
                        Find a dealer               findDealerEvent
                        Add to Compare              lgEvent
                    */
                    var event = ''
                	if(_dl.page_name.page_purpose == 'home'){
                    	category = makeString('homepage',templateName)
                    }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
                    	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
                    	category = nvl(category,_dl.page_name.super_category)
                    	category = makeString(category,templateName)
                    }else{
                    	category = templateName
                    }
                    
                    var action = ''
                    var label = nvl_def(_dl.page_name.sku)
                    var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase() //LGEUS-188
                    if(!bu) bu = nvl_def(_dl.page_name.bu); // LGEUS-188
                    var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
                    
                    if($(this).hasClass('where-to-buy')){
                    	//LGEUS-499
                    	var txt = $(this).text().toLowerCase();
                    	if(txt == 'where to buy'){
	                        event = 'whereToBuyEvent';    
                    	}else{
                    		event = 'lgEvent'
                    	}
                        action = txt
                    }else if($(this).hasClass('find-a-dealer')){
                        event = 'findDealerEvent';
                        action = 'find a dealer'
                    }else if($(this).hasClass('inquiry-to-buy')){
                        event = 'lgEvent';
                        action = 'inquire to buy'
                    }else if($(this).hasClass('ico-compare')){
	                	event = 'lgEvent';
	                	if($(this).hasClass('added')){
	                		action = 'remove compare';
	                	}else{
	                		action = 'add to compare';
	                	}//DC-171 START
                    }else if ($(this).hasClass('klarna-learn-more')) {
                    	event = 'lgEvent';
                    	action = 'Klarna Learn More';  //DC-171 END
				    }//DC-1176 Start
					else if($(this).hasClass('bulkOrder')) {
						event = 'lgEvent';
						action = 'BULK ORDER FORM';
					}
					//DC-1176 END
					else {
                    	event = 'lgEvent'
                    	action = $(this).text()
                    	label = nvl_def(_dl.page_name.sku)
                    }
                    
                    if(!$(this).is('#keyFeaturesToggle') && !$(this).is('#zipCodeToggle') && !$(this).is('#zipCodeToggleMobile')) {
						addData(event,category,action,label,bu ,componentId )
					}
                }
        
            break;
            //Add to cart End
            
            //Add to Cart Pop Up Action
            case ($(this).hasClass('view-cart') || $(this).hasClass('proceed-checkout') || $(this).hasClass('continue-shopping')):
            	var event = 'lgEvent'
                var category = 'add to cart pop up'
                var action = ''
                var label =  nvl_def($(this).closest('.model-info').data('model-name'))
                var bu = nvl_def($(this).closest('.model-info').data('bu-name')).toLowerCase() //LGEUS-188
                var componentId = 'n/a'
                
                if($(this).hasClass('view-cart')){
                	action = 'view cart'
                }else if($(this).hasClass('proceed-checkout')){
                	action = 'proceed to checkout'
                }else if($(this).hasClass('continue-shopping')){
                	action = 'continue shopping'
                }
                addData(event,category,action,label,bu ,componentId )
            
            break;
                
            case ($(this).hasClass('add-to-cart-popup')):
            	var event = 'eec.addToCart'
                var action = 'add to cart'
				var category = 'add to cart pop up';

				//DC-905 Modified
				var subscriptionFlag = $(this).data('subscription-enabled');
				if( subscriptionFlag && subscriptionFlag == 'Y'){
					event = 'lgEvent';
					action = 'subscription - pop up';
					addData(event,category,action,label, bu , componentId );
				}else{
					var ecommerce = new Object()
					bu = nvl_def($(this).data('bu-name')); // LGEUS-188
					ecommerce.currencyCode = 'USD'
					var productsObj = new Object()
					productsObj.id = nvl_def($(this).data('model-name'))
					productsObj.name = nvl_def($(this).siblings('.model-title').text())
					productsObj.category = nvl($(this).data('category'))
					
					var price = nvl_def($(this).siblings('.price-area').find('.price-inner').find('.purchase-price .price').text())
					price = price.substr(price.indexOf('$')+1,price.length)
					price = nvl(price,'0')
					productsObj.price = replaceAll(price,',','')
					
					productsObj.position =  nvl_def($(this).data('position'))
					productsObj.quantity = '1'
					productsObj.dimension7 = nvl_def($(this).data('model-name')) 
					productsObj.dimension8 = nvl_def($(this).data('model-id'))
					productsObj.dimension9 = checkBU(bu) //DC-312, DC-405
					productsObj.dimension10 = ''
					productsObj.dimension11 = ''
					productsObj.dimension28 = 'N'
					productsObj.dimension29 = '0'
					productsObj.dimension36 = 'Y' 
					productsObj.dimension37 = 'N'
					productsObj.dimension38 = 'N'
					productsObj.dimension39 = ''
					productsObj.dimension51 = 'N'
					productsObj.dimension95 = 'N'
					productsObj.dimension116 = 'N'
					productsObj.dimension117 = 'N'
					productsObj.dimension118 = 'N'
					
					if($(this).siblings('.price-area').find('.price-inner').find('.product-price del').length > 0){
						productsObj.dimension28 = 'Y'
						var savePrice = nvl_def($(this).siblings('.price-area').find('.price-inner').find('.product-price .legal').text())
						savePrice = savePrice.substr(savePrice.indexOf('$')+1,savePrice.length)
						productsObj.dimension29 = replaceAll(savePrice,',','')
					}
					var productDetailArray = new Array();
					productDetailArray.push(productsObj)
					
					ecommerce.add = new Object() 
					ecommerce.add.actionField = {list : 'recommended accessories - add to cart pop up'}
					ecommerce.add.products = productDetailArray
					
					addData2(event, category, action, label, bu, componentId, ecommerce)
				}
            	//DC-905 End
            	
            break;
            //Add to Cart Pop up end
                
            //PLP Add to cart
            case $(this).closest('.ga-model').find('.ga-model-info').length > 0 :
                var event = ''
                var category = ''
                
            	if(_dl.page_name.page_purpose == 'home'){
                	category = makeString('homepage',templateName)
                }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
                	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
                	category = nvl(category,_dl.page_name.super_category)
                	category = makeString(category,templateName)
                }else{
                	category = templateName
                }
            	
                var action = ''
                var label =  $(this).closest('.ga-model').find('.ga-model-info').attr('data-ga-modelname')
                label = nvl_def(label)
                var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase() //LGEUS-188
                var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
                
                if($(this).hasClass('ga-model-detail')  || $(this).hasClass('add-to-cart') || $(this).hasClass('where-to-buy') || $(this).hasClass('find-a-dealer')  ){
                    //product click, where-to-buy, add to cart, find a dealer
                	
                    var ecommerce = new Object()
                    ecommerce.currencyCode = 'USD'
                    var addObj = new Object()
                    var productsObj = new Object()
                    var productDetailArray = new Array();
                    var eCommerceFlag = 'Y'
                    if (!bu) bu = nvl_def($(this).closest('.ga-model-info').attr('data-bu-name')).toLowerCase(); // LGEUS-188
                    if (!bu) bu = nvl_def($(this).siblings('.ga-model-info').attr('data-bu-name')).toLowerCase(); // LGEUS-188
                    if (!bu) bu = nvl_def($(this).parents().siblings('.ga-model-info').attr('data-bu-name')).toLowerCase(); // LGEUS-188
                    
                    
                    var listString = makeString(category, componentId)
                    
                    addObj.actionField = {list : listString}
                    addObj.products = productDetailArray
                    productDetailArray.push(productsObj)
                    
                    var pId = nvl_def($(this).closest('.ga-model').find('.sku').text());
                    if(pId=="" || pId==null || pId == "null" || pId=="undefined"){
                    	pId = nvl_def($(this).closest('.ga-model').find('.ga-model-info').attr('data-ga-modelname'))
                    }
                    productsObj.id = pId
                    productsObj.name = nvl_def($(this).closest('.ga-model').find('.ga-model-info').find('.model-name').text())
                    
                    
                    var pcategory =  nvl_def($(this).closest('.ga-model').find('.ga-model-detail').attr('href'))
                    pcategory = pcategory.substring(pcategory.indexOf('/us'))
                    pcategory = pcategory.split('/')[2]
                    pcategory = nvl(pcategory,_dl.page_name.category)
                    productsObj.category = pcategory
                    
                    var price = nvl_def($(this).closest('.ga-model').find('.ga-price').text())
                    price = price.substr(price.indexOf('$')+1,price.length)
                    price = nvl(price,'0')
                    productsObj.price =  replaceAll(price,',','')

                    var pIndex = 0
                    if($(this).closest('ul').length>0){
                        pIndex = $(this).closest('li').index()+1
                    }else{
                        pIndex = $(this).closest('.item').index()+1
                    }
                    productsObj.position = makeString(componentId,pIndex)  
                    	
                    if($(this).hasClass('add-to-cart')){
                        productsObj.quantity = '1'
                    }
                    
                    productsObj.dimension7 = $(this).closest('.ga-model').find('.ga-model-info').attr('data-ga-modelname')
                    
                    var mdId 
                	if(standardData.siteType =="B2B"){
                     	mdId =  nvl_def($(this).closest('.ga-model').find('.sku').text())
                	}else if($(this).closest('.ga-model').find('.rating').length>0){
                     	mdId = nvl_def($(this).closest('.ga-model').find('.rating').attr('data-bv-product-id'))
                	}else{
                        mdId = nvl(mdId,$(this).attr('data-model-id'))
                	}
                    
                    mdId = nvl(mdId, $(this).closest('.ga-model').find('.ga-model-info').attr('data-ga-modelid') ) //LGEUS-523 
                    productsObj.dimension8 = mdId
                    
                    productsObj.dimension9 = checkBU(bu) // LGEUS-188, DC-312, DC-405
                    
                    var reviewArray = $(this).closest('.ga-model').find('.rating a').attr('aria-label')
                    reviewArray = nvl_def(reviewArray)
                    if(reviewArray == "No rating value" || reviewArray == "" || reviewArray == "undefined" || reviewArray == "null"){
                    	productsObj.dimension10 = '0'
                    	productsObj.dimension11 = '0'
                    }else{
                    	reviewArray = reviewArray.split(' ')
                    	productsObj.dimension10 = nvl(reviewArray[5],'0')
                        productsObj.dimension11 = nvl(reviewArray[0],'0')   
                    }               
                    productsObj.dimension28 = 'N'
                    productsObj.dimension29 = '0'
                    productsObj.dimension36 = 'N'
                    productsObj.dimension37 = 'N'
                    productsObj.dimension38 = 'N'
                    productsObj.dimension39 = ''
                    productsObj.dimension51 = 'N'
                    productsObj.dimension95 = 'N'
                    productsObj.dimension116 = 'N'
                    productsObj.dimension117 = 'N'
                    productsObj.dimension118 = 'N'
                    	
	            	if($(this).closest('.ga-model').find('.ga-prevprice').length > 0) {
	                     productsObj.dimension28 = 'Y'
	                     var savePrice = nvl_def($(this).closest('.ga-model').find('.ga-saveprice').first().text())
	                     savePrice = savePrice.substr(savePrice.indexOf('$')+1,savePrice.length)
	                     productsObj.dimension29 = replaceAll(savePrice,',','')
	                }	
                    	
                    if($(this).closest('.ga-model').find('.products-info').length > 0){
                    	if($(this).closest('.ga-model').find('.add-to-cart.active').length > 0){
                            productsObj.dimension36 = 'Y'
                        }

                        if($(this).closest('.ga-model').find('.inquiry-to-buy.active').length > 0){
                            productsObj.dimension38 = 'Y'
                        }
                        if($(this).closest('.ga-model').find('.promotion-text').text().length > 0){
                            var promotions = $(this).closest('.ga-model').find('.promotion-text')
                        	var promotionList = ""
                    		for ( var i = 0, l = promotions.length; i < l; i++ ) {
                    			promotionList = promotionList + nvl_def(promotions.eq(i).text())
                    		}
                            
                        	productsObj.dimension39 = promotionList
                        }

                        if($(this).closest('.ga-model').find('.find-a-dealer.active').length > 0){
                            productsObj.dimension95 = 'Y'
                        }

                        if($(this).closest('.ga-model').find('.where-to-buy.active').length > 0){
                            //Where to buy Where to Buy 37, Buy Now 51, Sign Up 116, Pre-Order 117, Notify Me 118
                        	
                        	var wtbText = nvl_def($(this).closest('.ga-model').find('.where-to-buy').text().toLowerCase())
                            if(wtbText == 'where to buy'){
                                productsObj.dimension37 = 'Y'
                            }else if(wtbText == 'buy now'){
                                productsObj.dimension51 = 'Y'
                            }else if(wtbText == 'sign up'){
                                productsObj.dimension116 = 'Y'
                            }else if(wtbText == 'pre-order'){
                                productsObj.dimension117 = 'Y'
                            }else if(wtbText == 'notify me'){
                                productsObj.dimension118 = 'Y'
                            }
                        }
                    }else{
                    	if($(this).closest('.ga-model').find('.add-to-cart').length > 0){
                            productsObj.dimension36 = 'Y'
                        }

                        if($(this).closest('.ga-model').find('.inquiry-to-buy').length > 0){
                            productsObj.dimension38 = 'Y'
                        }
                        if($(this).closest('.ga-model').find('.promotion-text').text().length > 0){
                            var promotions = $(this).closest('.ga-model').find('.promotion-text')
                        	var promotionList = ""
                    		for ( var i = 0, l = promotions.length; i < l; i++ ) {
                    			promotionList = promotionList + nvl_def(promotions.eq(i).text())
                    		}
                            
                        	productsObj.dimension39 = promotionList
                        }

                        if($(this).closest('.ga-model').find('.find-a-dealer').length > 0){
                            productsObj.dimension95 = 'Y'
                        }

                        if($(this).closest('.ga-model').find('.where-to-buy').length > 0){
                        	var wtbText = nvl_def($(this).closest('.ga-model').find('.where-to-buy').text().toLowerCase())
                            if(wtbText == 'where to buy'){
                                productsObj.dimension37 = 'Y'
                            }else if(wtbText == 'buy now'){
                                productsObj.dimension51 = 'Y'
                            }else if(wtbText == 'sign up'){
                                productsObj.dimension116 = 'Y'
                            }else if(wtbText == 'pre-order'){
                                productsObj.dimension117 = 'Y'
                            }else if(wtbText == 'notify me'){
                                productsObj.dimension118 = 'Y'
                            }
                        }
                    }

					var subscriptionFlag = $(this).data('subscription-enabled'); //DC-905
                   
                    if($(this).hasClass('ga-model-detail')){
                        event = 'eec.productClick'
                        action = 'Product Click'
                        ecommerce.click = addObj
                    }else if($(this).hasClass('where-to-buy')){
                    	//LGEUS-499
                    	var txt = $(this).text().toLowerCase();
                    	if(txt == 'where to buy'){
	                        event = 'whereToBuyEvent'
	                        ecommerce.click = addObj
                    	}else{
                    		eCommerceFlag = 'N'
                    		event = 'lgEvent'
                    	}
                        action = txt
                    }else if($(this).hasClass('add-to-cart')){
						//DC-905 Modified
						if( subscriptionFlag && subscriptionFlag == 'N'){
							event = 'eec.addToCart'
							action = 'Add To Cart'
							productsObj.quantity = '1'
							ecommerce.add = addObj
						}else {
							event = 'lgEvent'
							action = 'subscription - pop up'
						}
						//DC-905 End
                    }else if($(this).hasClass('find-a-dealer')){
                        event = 'findDealerEvent'
                        action = 'Find a dealer'
                        ecommerce.click = addObj
                    }
                    //LGEUS-499
                    if(eCommerceFlag == 'Y' && (!subscriptionFlag || subscriptionFlag == 'N')){ //DC-905 Modified
	                    addData2(event, category, action, label, bu, componentId, ecommerce)
                    }else{
                    	addData(event,category,action,label, bu , componentId )
                    }
                }else{
                    //inquiry to buy, add to compare
                    event = 'lgEvent'
                	if($(this).hasClass('inquiry-to-buy')){
                		action = makeString($(this).closest('.component').attr('class').split(' ')[2],'inquiry to buy') 
                		addData(event,category,action,label, bu , componentId )
                    }else if($(this).hasClass('js-compare')){
                    	//PJTUSPDP-1 START
                    	if($(this).hasClass('added')){
                    		action = makeString($(this).closest('.component').attr('class').split(' ')[2],'remove compare') 
                    	}else{
                    		action = makeString($(this).closest('.component').attr('class').split(' ')[2],'add to compare') 
                    	}
                    	addData(event,category,action,label, bu , componentId )
                    	//PJTUSPDP-1 END
                    }

                }

            break;
            //PLP End

            case standardData.pageType == "PRODUCT" :
            
                var event = 'lgEvent'
                var category = templateName
                var action = nvl_def($(this).closest('.component').attr('ga-component-name'))
                var bu = nvl_def($(this).closest('.ga-model-info').data('bu-name')).toLowerCase(); //LGEUS-188
                if(!bu) bu = nvl_def(_dl.page_name.bu); // LGEUS-188
            
            	
                var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
            
            	if($(this).parents().hasClass('resource-item-download')){
            		var label = nvl_def(_dl.page_name.sku)
            		var resourceGroup = nvl_def($(this).closest('.resource-item').find('.resource-item-title').text())
            		label = makeString(label,resourceGroup)
            		label = makeString(label, 'download')
            		bu = checkBU(bu); //DC-312, DC-405
            		var fileName = nvl_def($(this).text())
            		
            		dataLayer.push({
            		        'event': 'lgEvent',
            		        'category': category,
            		        'action': 'pdp resource',
            		        'label': label,
            		        'businessUnit': bu, // LGEUS-188
            		        'componentID': componentId,
            		        'fileName' : fileName
            		});
            		
            	}else if($(this).parents().hasClass('find-a-dealer-wrap') && standardData.siteType == "B2B"){
            		
            		var label = nvl_def(_dl.page_name.sku)
            		action = makeString('find a dealer map',$(this).text())
            		bu = checkBU(bu); //DC-312, DC-405
            		var seller = $(this).closest('.list').find('.dealer-name').text()
            		var stock = 'true'
            		var distance =''
            		var storeId = ''
            		
            		dataLayer.push({
        		        'event': 'lgEvent',
        		        'category': category,
        		        'action': action,
        		        'label': label,
        		        'businessUnit': bu, // LGEUS-188, DC-312, DC-405
        		        'componentID': componentId,
        		        'seller' : seller,
        		        'stock' : stock,
        		        'distance' : distance,
        		        'storeId' : storeId
            		});
            			
            	}else{
            		var label = nvl_def(_dl.page_name.sku)
                    if($(this).parents().hasClass('pdp-specs-content') || $(this).parents().hasClass('ico-download')){
                        //Print / Energy Guide click , resources download (B2B), For more information, click here                     
                        label = makeString(label, $(this).text())
                    }else if($(this).attr('data-link-area') == "pdp_support-support_link_area" || componentId == "C0015"){
                        //support area
                        label = makeString(label, $(this).attr('data-link-name'));
						if ($(this).attr('id') === 'warranty-modal-button') {
							label = label + ' - warranty information';
						}
                    }else if(componentId == "C0018" || componentId == "C0019"){
                    	label = makeString(label, 'lg b2b partner portal')
                    }
                    addData(event,category,action,label, bu , componentId )
            	}

            break; 
            //PDP End

            //Promotion Start
            case ($(this).attr('data-promotion-id') != null) || ($(this).attr('data-firstpromotion-id') != null) || ($(this).attr('data-secondpromotion-id') != null)  || ($(this).parent().siblings('.btn-box').find('a').attr('data-promotion-id') != null):
                var event = 'eec.promoClick'
                var category = ''  
                	
            	if(_dl.page_name.page_purpose == 'home'){
                	category = makeString('homepage',templateName)
                }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
                	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
                	category = nvl(category,_dl.page_name.super_category)
                	category = makeString(category,templateName)
                }else{
                	category = templateName
                }
            
                var action = 'promo click'
                var label = ""
                var bu = nvl_def(_dl.page_name.bu)
                if (!bu) bu = nvl_def($(this).closest('.ga-business-unit').attr('data-bu-name')); // LGEUS-188
                var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
                var ecommerce = new Object()
                var addObj = new Object()
                var productsObj = new Object()
                var productDetailArray = new Array();
                ecommerce.promoClick = addObj
                
                addObj.promotions = productDetailArray
                productDetailArray.push(productsObj)
                
                var promotionDate = ''
                if($(this).attr('data-firstpromotion-id') != null){
                    productsObj.id = $(this).attr('data-firstpromotion-id')
                    productsObj.name = $(this).attr('data-firstpromotion-name')
                    label = $(this).attr('data-firstpromotion-name')
                    productsObj.creative = $(this).attr('data-firstpromotion-file-path')
                    productsObj.position =  componentId + ' - ' + $(this).attr('data-promotion-index')
                    
                    promotionDate = nvl_def($(this).attr('data-firstpromotion-date'))
      
                }else if($(this).attr('data-secondpromotion-id') != null){
                    productsObj.id = $(this).attr('data-secondpromotion-id')
                    productsObj.name = $(this).attr('data-secondpromotion-name')
                    label = $(this).attr('data-secondpromotion-name')
                    productsObj.creative = $(this).attr('data-secondpromotion-file-path')
                    productsObj.position =  componentId + ' - ' + $(this).attr('data-promotion-index')
                    
                    var promotionDate = nvl_def($(this).attr('data-secondpromotion-date'))
  
                    
                }else if($(this).parent().hasClass('visual-area')){
                	
                	var btnArea =  $(this).parent().siblings('.btn-box').find('a')
                	if(btnArea.attr('data-promotion-name') == null){
                		productsObj.id = btnArea.attr('data-promotion-id')
	                    productsObj.name = nvl_def(btnArea.closest('.unit-box').find('.head-copy').text())
	                    label = nvl_def(btnArea.closest('.unit-box').find('.head-copy').text())
	                    productsObj.creative = btnArea.closest('.unit-box').find('.visual-area').find('img').attr('data-src')
	                    productsObj.position =  componentId + ' - ' + btnArea.attr('data-promotion-index')
	                    
	                    var promotionDate = nvl_def(btnArea.closest('.unit-box').find('.body-copy').text())

                		
                	}else{
                		productsObj.id = btnArea.attr('data-promotion-id')
                		productsObj.name = btnArea.attr('data-promotion-name')
                		label =  btnArea.attr('data-promotion-name')
                		productsObj.creative = btnArea.closest('.unit-box').find('.visual-area').find('img').attr('data-src')
	                    productsObj.position =  componentId + ' - ' + btnArea.attr('data-promotion-index')
	                    
	                    var promotionDate = nvl_def(btnArea.attr('data-promotion-date'))
                		
                	}
                	
                }else if($(this).attr('data-promotion-name') == null){
                    productsObj.id = $(this).attr('data-promotion-id')
                    productsObj.name = nvl_def($(this).closest('.unit-box').find('.head-copy').text())
                    label =  nvl_def($(this).closest('.unit-box').find('.head-copy').text())
                    productsObj.creative = $(this).closest('.unit-box').find('.visual-area').find('img').attr('data-src')
                    productsObj.position =  componentId + ' - ' + $(this).attr('data-promotion-index')
                    
                    var promotionDate = nvl_def($(this).closest('.unit-box').find('.body-copy').text())

                    
                }else{
                    productsObj.id = $(this).attr('data-promotion-id')
                    productsObj.name = $(this).attr('data-promotion-name')
                    label =  $(this).attr('data-promotion-name')
                    productsObj.creative = $(this).closest('.unit-box').find('.visual-area').find('img').attr('data-src')
                    productsObj.position =  componentId + ' - ' + $(this).attr('data-promotion-index')
                    
                    var promotionDate = nvl_def($(this).attr('data-promotion-date'))
                    
                }
                
                var startDateValue = $.trim(nvl(promotionDate,'').split('~')[0]);
				var endDateValue = $.trim(nvl(promotionDate,'').split('~')[1]);
				var startDateArray = startDateValue.split('/');
				var endDateArray = endDateValue.split('/');
				
				var startDate = ''; 
				var endDate = '';
				if(startDateArray.length == 1){
					startDate = startDateArray[0]
					endDate = endDateArray[0]
				}else{
					if(startDateArray.length < 3){
						startDate = endDateArray[2] + '-' + startDateArray[0] + '-' + startDateArray[1];
					}else if(startDateArray.length >= 3){
						startDate = startDateArray[2] + '-' + startDateArray[0] + '-' + startDateArray[1];
					}
					endDate = endDateArray[2] + '-' + endDateArray[0] + '-' + endDateArray[1];
				}
				
				productsObj.dimension30 = startDate
                productsObj.dimension31 = endDate
                productsObj.id = productsObj.id +"|" + startDate +"|" + endDate
				
                addData2(event,category,action,label, bu , componentId, ecommerce )

            break;
            //Promotion End
            
            //ETC CTA button
            default :
                var event = 'lgEvent'
                var category = '' 
                var label = ''
            	if(_dl.page_name.page_purpose == 'home'){
                	category = makeString('homepage',templateName)
                }else if(_dl.page_name.page_purpose == 'category' || _dl.page_name.page_purpose == 'sub-category' || _dl.page_name.page_purpose == 'discontinued' || _dl.page_name.page_purpose == 'super-category'){
                	category = nvl(_dl.page_name.sub_category,_dl.page_name.category)
                	category = nvl(category,_dl.page_name.super_category)
                	category = makeString(category,templateName)
                }else{
                	category = templateName
                }
            	
            	
                var action = nvl_def($(this).closest('.component').attr('ga-component-name'))
                
                var link = ''
                if($(this).find('.title').text().length>0){
                	link = nvl_def($(this).find('.title').text())
                }else if( $(this).find('.model-name').text().length>0){
                	link = nvl_def($(this).find('.model-name').text())
                }else if(nvl_def($(this).text()).length>0){
                	link = nvl_def($(this).text())
                }else{
                	link = nvl_def($(this).attr('data-link-name'))
                }
               
                var title = nvl_def($(this).closest('.component').children('.title').text())
                if(componentId == 'C0071'){
                	title = nvl_def($(this).closest('.unit-box').find('.title').text())
                }
                
                label = makeString(title,link);
                
                var bu = nvl_def($(this).closest('.ga-business-unit').attr('data-bu-name')); // LGEUS-188
                if (!bu) bu = nvl_def(_dl.page_name.bu) // LGEUS-188

                var componentId = nvl_def($(this).closest('.component').attr('class')).split(' ')[1]
                
               
                
                addData(event,category,action,label, bu , componentId )
        }
    });
};
        
//add 6 data
function addData(event, category, action, label, businessUnit, componentID){
	var bu = checkBU(businessUnit); //DC-312, DC-405
	
    dataLayer.push({
        'event': event,
        'category': category,
        'action': action,
        'label': label,
        'businessUnit': bu, // LGEUS-188, DC-312, DC-405
        'componentID': componentID
    });
};

//add 7 data
function addData2(event, category, action, label, businessUnit, componentID, ecommerce){
    var bu = checkBU(businessUnit); //DC-312, DC-405
    	
    dataLayer.push({
        'event': event,
        'category': category,
        'action': action,
        'label': label,
        'businessUnit': bu, // LGEUS-188, DC-312, DC-405
        'componentID': componentID,
        'ecommerce': ecommerce
    });
};

//DC-312, DC-405
function checkBU(businessUnit){
	if(businessUnit == null || businessUnit == "") return "";
	
	var bu = businessUnit.toLowerCase();
	if(bu == "mu") return "mc";
	
	return bu;
}

function replaceAll(str, searchStr, replaceStr) {
   	return str.split(searchStr).join(replaceStr);
};

function makeString(str1, str2){
	var data1 = str1+"";
	data1 = data1.trim();
	var data2 = str2+"";
	data2 = data2.trim();
	var result = "";
	if( data1=="" || data1==null || data1=="null" || data1=="undefined"){
		if( data2=="" || data2==null || data2=="null" || data2=="undefined"){
			result =  "";
		}else{
			result =  data2;
		}
	}else{
		if( data2=="" || data2==null || data2=="null" || data2=="undefined"){
			result =  data1
		}else{
			result = data1 + " - " + data2
		}
	}

	result = replaceAll(result,'_',' ')
	return result;	
};


function nvl(str, defaultStr){
	var check = str+"";
	var result = "";
	check = check.trim()
	if(check=="" || check==null || check == "null" || check=="undefined"){
		result = defaultStr;
	}else{
		result = check;
	}
	return result ;
}

function nvl_def(str){
	var result = str+"";
	result = result.trim()
	if(result=="" || result==null || result == "null" || result=="undefined")
		result = "" ;
	
	result = result.replace(/\s\s+/g, ' ')
	result = replaceAll(result,'_',' ')
	return result ;
}

function getGNBCart(){
	var url = $('.navigation').eq(0).data('cart-check');
	var cookieName = 'LG5_CartID';
	var cartID = getCookie(cookieName);
	var amount = 0;
	var count= 0;
	
	if(url && cartID){
		var authToken = getToken();
		var headers = {};
		if(authToken != ''){
			headers.Authorization = "Bearer " + authToken;
		}
		headers.Store = $('.navigation').eq(0).data('store');
		headers["Content-type"] = "application/json";
		
		$.ajax({
			url : url,
			headers : headers,
			async: false,
			type : 'post',
			data : JSON.stringify({'query':'{cart(cart_id: "'+cartID+'") { items { product {   sku name price {\n        regularPrice {\n          amount {\n            value\n            currency\n          }\n        }\n      }    } quantity  } } }', 'variables':null, 'operationName':null}), //LGEUS-929 : 20200828 modify
			dataType : 'json',
			success : function(data){
				if(data.errors){
					console.log('error');
				} else {
					var len = data.data.cart.items.length;
					if(len>0){
						var items = data.data.cart.items;
						for(i=0; i<len; i++){
							var qty = data.data.cart.items[i].quantity; //LGEUS-929 : 20200828 modify
							count += parseInt(qty);
							var productPrice = parseFloat(items[i].product.price.regularPrice.amount.value * qty);
							amount += productPrice;
						}
					}
				}
			},
			error : function(request, status, error){
				console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
				$('body').trigger('ajaxLoadEnd');
			}
		});
	}
	 return {
	        amount: amount,
	        count: count
	    };
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function getToken(){
	var authToken = '';
	if(checkLogin()){
		authToken = getCookie('ACCESS_TOKEN');
	}
	return authToken;
}

function checkLogin(){
	var isLogin = false;
	var cookie = getCookie('ACCESS_TOKEN');
	if(!!cookie && parseJwt(cookie).context.type === 'user'){
		isLogin = true;
	}
	return isLogin;
}

function productMapInit(){
	return {
		id : '',
		list : '',
		position : '',
		dimension36 : 'N',
		dimension37 : 'N',
		dimension38 : 'N',
		dimension51 : 'N',
		dimension95 : 'N',
		dimension116 : 'N',
		dimension117 : 'N',
		dimension118 : 'N'
	};
}

function productDetailMap(id, componentName, position){
	return {
		id : id,
		list : id + ' - ' + componentName,
		position : componentName + ' - ' + position
	};
}

function addPromotionMap(id, startDate, endDate, title, creative, componentName, position){
	return {
		id : id + '|' + startDate + '|' + endDate,
		title : title,
		creative : creative,
		position : componentName + ' - ' + position,
		dimension10 : startDate,
		dimension11 : endDate
	};
}

function fixedEncodeURI (str) {
    return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']');
}

function getWishdata(){
	var authToken = getToken();
	var count = 0;
	var model = '';
	if(''!=authToken){
		$.ajax({
			url: '/us/mkt/ajax/product/retrieveWishlistData',
			//headers: headers,
			async: false,
			type: 'post',
			dataType: 'json',
			success: function(data) {
				count = data.count;
				model = data.model;
			},
			error: function(request, status, error) {
				$('body').trigger('ajaxLoadEnd');
			}
		})
	}
	return {
		count:count,
		model:model
	}
}
